@import url('https://fonts.cdnfonts.com/css/sf-pixelate?styles=9026');
/* @import url('https://fonts.cdnfonts.com/css/redaction?styles=81795');
@import url('https://fonts.cdnfonts.com/css/redaction?styles=81800'); */
@import url('https://fonts.googleapis.com/css?family=Inter');

body {
  font-family: 'SF Pixelate', 'Redaction 35', 'Redaction 50', 'Inter';
  font-weight: 100;
  background-color: rgb(0, 0, 0);
  /* background-image: url('./resources/sun2.jpg'); */
  /* background-size: cover; */
  height: 100vh;
  /* background-repeat: no-repeat; */
  /* background-position: bottom; */
}

.floating-pic-1 {
  position: absolute;
  z-index: -100;
  top: 10px;
  height: 500px;
  width: 200px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.app__main-wrapper {
  color: white;
  font-size: 1.8rem;
  font-weight: 100;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  left: 0;
  right: 0;
  top: 15vh;
  position: absolute;
  z-index: 10;
}

@media screen and (max-width: 1000px) {
  .app__main-wrapper {
    width: 600px;
  }
}

@media screen and (max-width: 680px) {
  .app__main-wrapper {
    width: 500px;
  }
}

@media screen and (max-width: 570px) {
  .app__main-wrapper {
    width: 400px;
  }
}

@media screen and (max-width: 440px) {
  .app__main-wrapper {
    width: 330px;
  }
}

@media screen and (max-width: 370px) {
  .app__main-wrapper {
    width: 280px;
  }
}

.app__opening-screen-wrapper {
  display: contents;
}
