li::before {
  content: '> ';
}

li {
  padding-top: 5px;
  list-style-type: none;
}

.main__heading {
  line-height: 2rem;
  font-size: 1.6rem;
  z-index: 10;
}

.main__heading-gif {
  width: 40px;
  height: fit-content;
}

.main__content {
  font-size: 0.9rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  padding: 1rem 0.5rem;
}

.main__list-title {
  margin-bottom: 0;
}

.main__link {
  color: white;
}

.main__link:hover {
  color: limegreen;
}

.main__pfp-wrapper {
  overflow: hidden;
  width: 100%;
}

.main__pfp-2 {
  width: 50%;
  transform: translateY(-70%) translateX(-10%) scale(3.5);
}

.main__pfp-3 {
  width: 45%;
  margin-bottom: -20%;
  transform: translateY(-30%) translateX(60%) scale(1);
}

.main__pfp-4 {
  width: 70%;
  transform: translateY(-40%) translateX(20%) scale(1.5);
}
