.opening-screen__wrapper {
  padding: 20px;
}

.box {
  padding-left: 5px;
  font-size: 1.5rem;
  overflow: hidden;
  top: 5px;
}

.opening-screen__box {
  position: absolute;
  height: 100%;
  width: 34%;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.opening-screen__box2 {
  position: absolute;
  height: 100%;
  width: 34%;
  background: white;
  bottom: 0;
  left: 33.33%;
  right: 0;
  overflow: hidden;
}

.opening-screen__box3 {
  position: absolute;
  height: 100%;
  width: 34%;
  background: white;
  bottom: 0;
  left: 66.66%;
  right: 0;
  overflow: hidden;
}

.text {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.opening-screen__first {
  z-index: 10;
  color: white;
  mix-blend-mode: difference;
  height: 40px;
  position: relative;
  overflow: hidden;
  top: 20vh;
}

.opening-screen__first span {
  position: absolute;
  left: 35%;
}

.opening-screen__middle {
  z-index: 10;
  color: white;
  mix-blend-mode: difference;
  height: 40px;
  position: relative;
  overflow: hidden;
  top: 25vh;
}

.opening-screen__middle span {
  position: absolute;
  left: 45%;
}

.opening-screen__last {
  z-index: 10;
  color: white;
  mix-blend-mode: difference;
  height: 40px;
  position: relative;
  overflow: hidden;
  top: 30vh;
}

.opening-screen__last span {
  position: absolute;
  left: 40%;
}

.break {
  margin-top: 50px;
}
